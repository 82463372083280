.footer-container {
    background-color: #007bff!important;
    color: #000;
}
.footer-link   {
color: aliceblue
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.footer-title {
    font-size: 34px;
    margin: 0;
}